#bug{
    padding: 20px;
    width: 100%;
    height: 90.5vh;
    overflow-y: auto;
    color: var(--fontColor);
}

#bug #bugUpper #bugInfo{
    display: flex;
    align-items: center;
}

#bug #bugInfo span:first-of-type{   
    margin: 0 5px;
}

#bug #bugUpper #bugInfo p{
    font-size: .8rem;
    opacity: .8;
}

#bug #bugUpper #bugInfo #due {
    opacity: .8;
    font-size: .8rem;
    margin-right: 10px;
}

#bug .labels{
    display: flex;
}

#bug .labels > *{
    margin: 0 2px;   
}

#bug #bugTitleStuff{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#bugTitleDiv{
    display: flex;
    align-items: center;
}

#bug #bugButtons{
    display: flex;
    align-items: center;   
}


#bug #bugButtons #notify{
    display: flex;
    cursor: pointer;
}

#bug #bugButtons #notify img{
    height: 18px;
    width: 18px;
    filter: var(--svgFilter);
}

#bug #bugButtons button{
    padding: 5px;
    border: none;
    border-radius: 6px;
    background-color: var(--green);
    font-weight: 600;
    color: white;
    cursor: pointer;
    min-width: 30px;
    margin-left: 5px;
}

#bug #bugButtons button:hover{
    background-color: var(--greenDarker);
}

#bug #bugTitle{
    margin-right: 5px;
}

#bug #fileInput{
   display: none; 
}


#bug #addImageLabel{
    height: 30px;
    width: 30px;
    cursor: pointer;
    border: 1px solid var(--fontColor);
    border-radius: 3px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
 }

 #bug #addImageLabel:hover{
    background-color: var(--notFont);
 }

#bug #addImageLabel img{
    filter: var(--svgFilter);
    align-self: center;
 }

#bug #addImageLabel #previewImages{
    display: flex;
 }


#bug #allImagesContainer ,#bug #imageStuff{
    display: flex;
 }

#bug #imageStuff .imgContainer{
    height: 30px;
    position: relative;
    margin-left: 3px;
}

#bug #imageStuff img{
    height: 30px;
    cursor: pointer;
}

#bug #imageStuff #bugImages{
    display: flex;
}

#bug #imageStuff img:hover{
    height: 300px;
    position: absolute;
    top: 0;
    z-index: 99;
}

#bug #bugDescDiv{
    padding: 5px 0;
}

#bug #bugDescDiv p{
    width: 90vw;
    overflow-wrap: break-word;
}

#bug #assigned #assignedText{
    display: flex;
    margin: 5px 0;
}

#bug #assigned #assignedText h4{
    margin-right: 5px;
}

#bug #assigned #assignedText #assignedUsers span{
    margin-left: 2px;
}

#bug #assigned #assignedText #assignedUsers span:not(:last-of-type):after{
    content: ",";
}

#bug #assigned #assignedText #assignedUser {
    position: relative;
}

#bug #assigned #assignedText .tooltip{
    display: none;
    position: absolute;
    background-color: var(--backgroundColor);
    z-index: 99;
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.57);
    margin-top: 5px;
    border-radius: 3px;
}

#bug #assigned #assignedText .assignedUser:hover .tooltip{
    display: block;
}

#bug #assigned #assignedButtons{
    display: flex;
    position: relative;
}

#bug #assigned #assignedButtons button{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: .7;
    margin-right: 10px;
    color: var(--fontColor);
}

#bug #assigned #assignedButtons img{
    height: 14px;
    width: 14px;
    margin-right: 3px;
    filter: var(--svgFilter);
}

#bug #assigned #assignedButtons #assign{
    position: absolute;
    background-color: var(--backgroundColor);
    box-shadow: var(--insetBoxShadow);
    top: 120%;
    padding: 10px;
    min-width: 400px;
    border-radius: 12px;
}

#bug .bugButtons{
    display: flex;
}

#bug #addComment {
    width: max-content;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    box-shadow: 0px 0px 5px black inset;
    border-radius: 6px;
}

#bug #addComment button{
    padding: 5px;
    margin: 5px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    color: var(--fontColor);
    background-color: var(--notFont);
    border: var(--borderBottomColor);
}

#bug #addComment button:hover{
    opacity: .9;
}

#bug #addComment textarea, #bug .comment textarea{
    resize: none;
    height: 100px;
    width: 50ch;
    border: none;
    padding: 10px;
    background-color: transparent;
    color: var(--fontColor);
}

#bug .comment {
    margin: 5px 0;
}

#bug .reply{
    padding: 0;
    box-shadow: 0px 0px 5px black inset;
    border-radius: 6px;
    width: max-content;
    color: var(--fontColor);
}
#bug .reply textarea{
    padding: 3px;
}
#bug .reply textarea:focus{
    outline: none;
}

#bug .reply .replyButtons{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5px;
}
#bug .reply .replyButtons button{
    font-weight: 700;
    margin: 0 10px;
    color: var(--fontColor);
}



#bug #addComment textarea:focus{
    outline: none;
}

#bug #comments .comment{
    padding: 5px 0 0 10px;
    border-left: 1px solid var(--fontColor);
}
#bug #comments .comment > *{
    margin: 5px 0;
}

#bug #comments .comment .commentAuthorInfo{
    display: flex;
    align-items: center;
}

#bug #comments .comment .commentAuthorInfo > *{
    margin-right: 10px;
}
#bug #comments .comment .commentAuthorInfo .postDate{
    font-size: .8rem;
    opacity: .8;
}

#bug #comments .comment .commentOptions button{
    background-color: transparent;
    border: none;
    opacity: .75;
    font-size: .8rem;
    cursor: pointer;
    color: var(--fontColor);
}

#bug .open {
    background-color: var(--openBug);
    color: var(--openColor);
}

#bug .closed {
    background-color: var(--closeBug);
    color: var(--closeColor);
}

#bug .archived {
    background-color: var(--statusArchived);
    color: var(--statusArchivedFont);
}

#bug .ongoing {
    background-color: var(--ongoingBug);
    color: var(--ongoingColor);
}

#bug .inReview {
    background-color: var(--inReviewBug);
    color: var(--inReviewColor);
}

#submit{
    position: absolute;
    right: 0;
    bottom: 0;
}