html{
    height: 100%;
    width: 100%;
}

*{
    transition: .1s ease;
}

:root {
    --monospace: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    --defFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

    --red: #de553d;
    --redDarker: #7f2313;
    --green: #3e7449;
    --greenDarker: #24572e;
    --lightBlue: rgb(206, 255, 239);
    --darkBlue: #02353c;
    
    --consoleUpper: #02353c;
    --consoleSide: #001316;
    --fontColor: #000;
    --notFont: rgb(172, 172, 172);
    --backgroundColor: #fff;
    --dashBackground: #e4e4e4;
    --borderBottomColor: rgba(0, 0, 0, 0.75);
    --borderBottomColorLight: rgba(0, 0, 0, 0.25);
    --insetBoxShadow: inset 0px 0px 10px rgba(128, 128, 128, 0.5);
    --tableHover: rgb(238, 238, 238);
    --searchBack: #e0d5e1;
    --inputBackground: rgb(245, 245, 245);
    --borderColor: rgba(0, 0, 0, 0.211);
    --backgroundHover: #ececec;

    --openBug: rgb(111, 110, 26);
    --openFont: rgb(253, 253, 253);
    --closeBug: rgb(38, 74, 18);
    --closeColor: rgb(251, 251, 251);
    --ongoingBug: rgb(2, 0, 91);
    --ongoingColor: rgb(109, 181, 235);
    --inReviewBug: rgb(0, 170, 186);
    --inReviewColor: rgb(4, 0, 126);

    --statusOpen: rgb(103, 204, 103);
    --statusOpenFont: rgba(0, 88, 0, 0.541);
    --statusClose: rgba(103, 204, 103, 0.548);
    --statusCloseFont: rgba(1, 59, 1, 0.541);
    --statusArchived: rgba(41, 0, 163, 0.541);
    --statusArchivedFont: rgba(255, 255, 255, 0.541);
    --statusOver: rgb(219, 103, 68);
    --statusOverFont: rgb(88, 18, 0);
    --statusDay: rgb(204, 197, 103);
    --statusDayFont: rgb(70, 59, 0);
    --statusWeek: rgb(154, 253, 154);
    --statusWeekFont: rgb(0, 88, 0);

    --priorityLow: #30753d;
    --priorityMed: rgb(168, 115, 0);
    --priorityHigh: #9d321f;

    --buttonColorDarkGreen: rgb(0, 41, 10);
    --buttonColorDarkGreenHover: rgb(2, 109, 25);
    
    --svgFilter: empty;

    --BGGradient: linear-gradient(
        236deg,
        rgba(111, 197, 128, 1) 0%,
        rgba(3, 80, 91, 1) 100%
    );
    /* ------------------------------------------------ */
}

:root .dark{
    --fontColor: #fff;
    --notFont: rgb(39, 39, 39);
    --backgroundColor: #181818;
    --dashBackground: #e4e4e4;
    --borderBottomColor: rgba(0, 0, 0, 0.75);
    --borderBottomColorLight: rgba(0, 0, 0, 0.25);
    --insetBoxShadow: inset 0px 0px 10px rgba(0, 0, 0, 0.904);
    --tableHover: rgb(41, 41, 41);
    --searchBack: #303030;
    --inputBackground: rgb(29, 29, 29);
    --borderColor: rgba(255, 255, 255, 0.226);
    --backgroundHover: #262626;

    --svgFilter: brightness(0) invert(1);
}

:root html body .light{
    --fontColor: #000;
    --notFont: rgb(172, 172, 172);
    --backgroundColor: #fff;
    --dashBackground: #e4e4e4;
    --borderBottomColor: rgba(0, 0, 0, 0.75);
    --borderBottomColorLight: rgba(0, 0, 0, 0.25);
    --insetBoxShadow: inset 0px 0px 10px rgba(128, 128, 128, 0.5);
    --tableHover: rgb(238, 238, 238);
    --searchBack: #e0d5e1;
    --inputBackground: rgb(245, 245, 245);
    --borderColor: rgba(0, 0, 0, 0.211);
    --backgroundHover: #ececec;

    --svgFilter: brightness(0);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transition: .2s ease;
}

a {
    color: var(--fontColor);
}

.avatar {
    height: 24px;
    width: 24px;
    display: block;
    background-image: url("../res/svg/user.svg");
    background-color: white;
    border-radius: 50%;
    align-items: center;
        overflow: hidden;
}

.avatar img {
    height: 100%;
    width: 100%;
    filter: none;
    transform: none;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

nav h2 {
    display: flex;
    align-items: center;
}

nav img {
    height: 50px;
    filter: brightness(0) invert(1);
}

nav ul {
    display: flex;
    list-style: none;
    align-items: center;
}

nav ul li {
    margin: 0 15px;
}

nav button {
    background-color: transparent;
    border: 1px solid white;
}

/* table */

table {
    font-size: 1rem;
    border-collapse: collapse;
}

table tr {
    border-bottom: 1px solid rgba(126, 126, 126, 0.438);
    transition: 0.2s ease;
}

table tr:first-of-type {
    text-transform: uppercase;
    /*
    font-size: 0.9rem;
    */
    font-weight: 600;
}

table tr:last-of-type {
    border-bottom: none;
}

table tr:first-of-type:hover {
    background-color: transparent;
}

table tr:hover {
    background-color: var(--tableHover);
}

table td {
    padding: 5px 8px;
    cursor: pointer;
}

table td:first-of-type {
    width: 8ch;
    text-transform: uppercase;
}

table td:last-of-type {
    width: 13ch;
}

textarea{
    resize: none;
}

.status {
    padding: 3px;
    font-size: 0.6rem;
    font-weight: 600;
    display: flex;
    background-color: var(--dashBackground);
    border-radius: 8px;
    justify-content: center;
    text-transform: uppercase;
}

.open .status {
    background-color: rgb(214, 212, 58);
    color: rgb(82, 82, 0);
}

.archived .status {
    background-color: var(--statusArchived);
    color: var(--statusArchivedFont);
}

.closed .status {
    background-color: rgb(115, 214, 58);
    color: rgb(0, 75, 0);
}

.overdue .status {
    background-color: rgb(252, 81, 58);
    color: rgb(75, 9, 0);
}

.pending .status {
    background-color: rgb(58, 158, 252);
    color: rgb(6, 0, 83);
}

.new .status {
    background-color: rgb(58, 213, 252);
    color: rgb(6, 0, 83);
}

.priority {
    font-size: 0.6rem;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    background-color: rgb(27, 27, 0);
    width: 5ch;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    padding: 4px 16px;
    margin: auto;
}

.low .priority {
    background-color: var(--priorityLow);
}

.med .priority {
    background-color: var(--priorityMed);
}

.high .priority {
    background-color: var(--priorityHigh);
}

.hidden {
    display: none;
}

.down{
    transform: rotate(180deg);
}

.label{
    font-size: 0.5rem;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    background-color: rgb(27, 27, 0);
    min-width: fit-content;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    padding: 4px 5px;
    margin-right: 3px;
}

table a{
    text-decoration: none;
}

#confirmationBoxDiv{
    position: absolute;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    border-radius: 6px;
    box-shadow: 0px 0px 15px black;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}
#confirmationBox{
    background-color: white;
    box-shadow: 0px 0px 15px black;
    border-radius: 6px;
    min-height: 200px;
    min-width: 300px;
    border-radius: 6px;
    box-shadow: 0px 0px 15px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#confirmationBox button{
    margin: 0 10px;
    padding: 5px 15px;
    border: none;
    background-color: var(--green);
    border-radius: 6px;
    color: white;
    cursor: pointer;
}
#confirmationBox button:nth-of-type(2){
    background-color: var(--red);
}
#confirmationBox #confirmationInfo{
    width: 30ch;
    margin-bottom: 30px;
}
#confirmationBox #confirmationInfo p{
    text-align: center;
}

.submitClosed {
    display: none;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: var(--notFont);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
    background-color: var(--fontColor);
}

#feedDiv .feedItem .feedAvatarBack {
    align-self: baseline;
    height: 24px;
    width: 24px;
    border-radius: 50%;
}

#feedDiv .feedAvatar {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-image: url("../res/svg/system.svg");
    background-position: center;
    background-size: contain;
    filter: var(--svgFilter);
}

#feedDiv .feedAvatarBack .new {
    background-image: url("../res/svg/plus.svg");
    background-size: 80%;
    background-repeat: no-repeat;
}

#feedDiv .feedAvatarBack .close {
    background-image: url("../res/svg/tick.svg");
    background-size: 80%;
    background-repeat: no-repeat;
}

#feedDiv .feedAvatarBack .reopen {
    background-image: url("../res/svg/reopen.svg");
    background-repeat: no-repeat;
}

#feedDiv .feedAvatarBack .edit {
    background-image: url("../res/svg/edit.svg");
    background-size: 80%;
    background-repeat: no-repeat;
}

#feedDiv .feedAvatarBack .delete {
    background-image: url("../res/svg/trash.svg");
    background-size: 80%;
    background-repeat: no-repeat;
}

#feedDiv .feedAvatarBack .user {
    background-image: url("../res/svg/user.svg");
}

#feedDiv .feedAvatarBack .project {
    background-image: url("../res/svg/project.svg");
}

#feedDiv .feedAvatarBack .change {
    background-image: url("../res/svg/change.svg");
}

#feedDiv .feedAvatarBack .inReview {
    background-image: url("../res/svg/paper.svg");
    background-size: 80%;
    background-repeat: no-repeat;
}

#feedDiv .feedItem .feedAvatarBack img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
}

#feed #feedDiv {
    color: var(--fontColor);
}

#feed #feedDiv .feedItem {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--borderColor);
}

#feedDiv .feedItem>div {
    margin-left: 5px;
}

#feedDiv .feedItem>div>div {
    display: flex;
    align-items: center;
}

#feedDiv .feedItem>div span {
    font-size: .7rem;
    margin-left: 15px;
}

.labels .low {
    background-color: var(--priorityLow);
    font-weight: 600;

}

.labels .med {
    background-color: var(--priorityMed);
}

.labels .high {
    background-color: var(--priorityHigh);
}

#confirmationBox {
    color: var(--fontColor);
    background-color: var(--backgroundColor);
}

.link{
    text-decoration: none;
}

.hide{
    display: none;
}

.showMobile{
    display: none;
}

@media screen and (max-width: 700px) {
    .hideMobile{
        display: none;
    }
    .showMobile{
        display: block;
    }
}