#newTeam{
    background-color: var(--backgroundColor);
    color: var(--fontColor);
    display: flex;
    flex-direction: column;
    width: 100%;
}

#newTeam > h1{
    display: flex;
    justify-content: center;
}

#newTeam #teamOpt{
    display: flex;
    justify-content: space-around;
    height: 100%;
}

#newTeam #teamOpt h2{
    margin-bottom: 10px;
}

#newTeam  #teamOpt > div{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 4px black;
    margin: 10px;
    width: 100%;
    border-radius: 8px;
    padding: 20px;
}

#newTeam .textSearch{
    height: 1.5rem;
    display: flex;
    align-items: center;
    border-radius: 3px;
    overflow: hidden;
    height: 30px;
    width: max-content;
    background-color: var(--notFont);
    padding: 15px 0;
}
#newTeam .textSearch button{
    width: 30px;
    padding: 3px 5px;
    border: hidden;
    background-color: var(--green);
    cursor: pointer;
}
#newTeam .textSearch button img{
    filter: brightness(0) invert(1);
    text-align: center;
    padding-top: 5px;
}

#teamFound{
    height: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 6px;
    background-color: var(--backgroundColor);
    display: flex;
    flex-direction: column;
}

#newTeam .team{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--borderColor);
    padding: 10px;
    align-items: center;
}
#newTeam .team h3{
    font-weight: 500;
}
#newTeam .team button{
    font-weight: 500;
    background-color: var(--green);
    border: none;
    color: white;
    padding: 5px;
    font-size: 1rem;
    border-radius: 3px;
    cursor: pointer;
}

#newTeam #createTeam form > *{
    margin-top: 10px;
}
#newTeam #createTeam .formField{
    width: max-content;
    padding: 5px 0;
    align-items: center;
    background-color: var(--notFont);
    border-radius: 3px;
}

#newTeam button{
    background-color: var(--green);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 3px;
    color: white;
    font-size: 1rem;
}

#newTeam button:hover {
    background-color: var(--greenDarker);
}

#newTeam #createTeam button img{
    height: 16px;
    width: 16px;
    margin-left: 4px;
    filter: brightness(0) invert(1);
}

.CustomInput {
    width: 30ch;
    padding: 0 5px;
    height: 24px;
    font-family: var(--monospace);
    font-size: 1.3rem;
    border: hidden;
    background-color: transparent;
}

.CustomInput:focus {
    outline: none;
}

.CustomInput::placeholder {
    color: rgb(90, 90, 90);
}

#createAndInvitations > div:first-of-type{
    margin-bottom: 10px;
}

#newTeam input {
    background-color: var(--notFont);
    color: var(--fontColor);
}

#newTeam table td:first-of-type {
    text-transform: none;
}

#newTeam .invite {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--borderColor);
    padding: 10px 0;
    margin: 3px 0;
}

#newTeam .invite:first-of-type {
    border-top: none;
}

#newTeam .invite h3{
    margin-right: 5px;
}

#newTeam .invite p{
    font-size: .9rem;
}

#newTeam .invite div{
    display: flex;
    align-items: center;
}

#newTeam .invite .inviteButtons button{
    margin-left: 5px;
    height: 30px;
}

#newTeam .invite .inviteButtons button:last-of-type{
    background-color: var(--red);
}

#newTeam .invite .inviteButtons button:last-of-type:hover{
    background-color: var(--redDarker);
}