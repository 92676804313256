/* General */

#console {
    display: flex;
    flex-flow: column;
    height: 100vh;
}

#console nav ul li {
    margin: 0 10px;
}

/* Nav */

#console nav img {
    height: 36px;
    width: 36px;
    filter: invert(1);
    margin-right: 10px;
}

#console nav {
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
    background-color: var(--darkBlue);
}

#console nav li a {
    color: var(--fontColor);
    text-decoration: none;
}

#console nav > * {
    display: flex;
    align-items: center;
}

#console ul {
    display: flex;
    list-style: none;
}

#console li select {
    background-color: transparent;
    color: white;
    border: none;
    font-size: 0.9rem;
    font-style: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    padding: 2px;
}

#console select:focus{
    outline: none;
}

#console select option{
    background-color: #00252b;
}

#console #notificationsItem {
    position: relative;
}

#console #notificationButton {
    cursor: pointer;
}

#console #notificationsItem img {
    margin: 0;
    height: 24px;
    width: 24px;
}

#console #notificationsItem #notification {
    position: absolute;
    height: 15px;
    width: 15px;
    bottom: 0;
    right: -5px;
    background-color: #00191d;
    color: white;
    font-size: 0.6rem;
    padding: 2px;
    border-radius: 50%;
    text-align: center;
}

#console #notificationsBox {
    position: absolute;
    top: 100%;
    width: 300px;
    background-color: var(--backgroundColor);
    color: var(--fontColor);
    border-radius: 6px;
    box-shadow: 0px 0px 5px black;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
}

#console #notificationsBox #notBoxEmpty {
    padding: 10px;
}

#console #notificationsBox .clearDiv {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    margin-bottom: 10px;
}

#console #notificationsBox button {
    padding-top: 8px;
    padding-right: 15px;
    opacity: .5;
    font-size: .8rem;
    color: var(--fontColor);
}

#console .notificationItem {
    border-top: 1px solid var(--borderColor);
    padding: 3px 20px;
}

#console .notificationItem .notifSource{
    margin-bottom: 5px;
    opacity: .5;
}

#console .notificationItem:first-of-type {
    padding-top: 0px;
}

#console .notificationItem .notifDate {
    font-size: 0.8rem;
    opacity: 0.7;
    text-align: end;
    padding: 8px 0 3px 0;
}

#user{
    position: relative;
}

#user button{
    display: flex;
    align-items: center;
}

#user button img{
    display: flex;
    transform: rotate(180deg) translateY(-10px);
    height: 10px;
    width: 10px;
}

#console #user .avatar{
    align-items: center;
    overflow: hidden;
}

#console #user .avatar img{
    height: 100%;
    width: 100%;
    filter: none;
    transform: none;
}

#console #dropdownDiv{
    position: absolute;
    top: 100%;
    right: 0;
    min-height: 100px;
    min-width: 100px;
    width: 30ch;
    background-color: var(--backgroundColor);
    box-shadow: 0px 0px 5px black;
    z-index: 99999;
    border-radius: 3px;
}

#console #dropdownDiv #darkmodeDiv{
    display: flex;
    justify-content: space-between;
}

#console #dropdownDiv #darkmodeDiv .switch{
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}
#console #dropdownDiv #darkmodeDiv .switch .slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 12px;
}

#console #dropdownDiv #darkmodeDiv .switch .slider:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

#console #dropdownDiv #darkmodeDiv input:checked+.slider {
    background-color: #000f1c;
}

#console #dropdownDiv #darkmodeDiv input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

#console #dropdownDiv #darkmodeDiv input:checked+.slider:before {
    -webkit-transform: translateX(11px);
    -ms-transform: translateX(11px);
    transform: translateX(11px);
}

#console #dropdownDiv ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#console #dropdownDiv ul li{
    margin: 0;
    font-size: .9rem;
    padding: 8px 10px;
    border-top: 1px solid var(--borderBottomColorLight);
    width: 100%;
    background-color: var(--backgroundColor);
    color: var(--fontColor);
}

#console #dropdownDiv ul li:first-of-type{
    opacity: .5;
    border: none;
}

#console #dropdownDiv ul li a, #console #dropdownDiv ul li button{
    color: var(--fontColor);
    font-size: .9rem;
}

/* screen */

#console #screen {
    display: flex;
    flex-grow: 1;
    background-color: var(--backgroundColor);
    position: relative;
}

#console #screen #consoleScreen {
    display: flex;
    flex-grow: 1;
}

/* Side Nav */
#console #sideNav {
    background-color: #001316;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: inset 0px 5px 10px black; /* box shadow maybe */
}

#console #sideNav ul {
    display: flex;
    flex-direction: column;
}

#console nav button{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1rem;
    font-family: var(--defFont);
    cursor: pointer;
}


#console #sideNav ul span:hover {
    background-color: #00252b;
    cursor: pointer;
}

#console #sideNav ul span {
    padding: 5px;
    transition: 0.2s ease;
    margin: 5px 0;
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#console #sideNav a{
    text-decoration: none;
}

#console #sideNav ul span img {
    height: 30px;
    width: 30px;
    filter: brightness(0) invert(1);
}

#console ul .activeTab {
    background-color: #00252b;
}

#console #sideNav ul span p {
    font-size: 0.75rem;
}

#console #sideNavMobile {
    display: none;
}

@media screen and (max-width: 700px) {
    #console nav img {
        height: 15px;
        width: 15px;
        margin-right: 5px;
    }
    
    #console nav {
        padding: 4px 10px;
    }

    #console #navLogo{
        height: 30px;
        width: 30px;
    }

    #console #sideNav {
        display: none;
    }

    
    .hamburgerMenuButton span{
        display: block;
        background-color: white;
        height: 1px;
        width: 25px;
        margin-bottom: 5px;
        z-index: 9999;
        transition: .2s ease;
    }
    
    .hamburgerActive span:nth-of-type(1){
        transform: rotate(45deg) translate(4px, 4px);
    }
    
    .hamburgerActive span:nth-of-type(2){
        opacity: 0;
    }
    
    .hamburgerActive span:nth-of-type(3){
        transform: rotate(-45deg) translate(4px, -4px);
    }

    .hamburgerMenuButton span:last-of-type{
        margin-bottom: 0;
    }
    
    #screen #sideNavMobile {
        position: absolute;
        top: 0;
        left: -100%;
        height: 100%;
        width: 70%;
        z-index: 9999;
        padding: 20px 10px;
        padding-top: 0;
        background-color: #001316;
        box-shadow: inset 0px 5px 10px black;
        color: white;
        flex-direction: column;
        transition: left .2s linear;
    }
    
    #console #screen .sideNavMobileActive{
        display: flex;
        left: 0;
    }

    #sideNavMobile li{
        list-style: none;
        margin-top: 20px;
        padding: 0 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.508);
    }

    #sideNavMobile li:last-of-type{
        margin-top: auto;
        padding-bottom: 0;
        border: none;
    }

    #sideNavMobile li span{
        display: flex;
        align-items: center;
    }

    #sideNavMobile li span img{
        height: 30px;
        width: 30px;
        margin-right: 10px;
        filter: grayscale(0) invert(1);
    }
    
    #sideNavMobile span p{
        font-size: 1.2rem;
        text-decoration: none;
    }
    
    #console li select {
        font-size: 1.2rem;
        padding: 0;
    }
    
    #console select:focus{
        outline: none;
    }
    
    #console select option{
        font-size: 1rem;
    }
}