#dashboard {
    background-color: var(--backgroundColor);
    display: grid;
    grid-gap: 10px;
    padding: 15px;
    grid-template-columns: 7fr 4fr;
    grid-template-rows: 1fr 1fr 4fr 1fr 1fr;
    grid-template-areas:
        "header side"
        "dBugs side"
        "dBugs side"
        "dBugs side"
        "dBugs side";
    width: 100%;
    max-height: calc(100vh - 58px);
    color: var(--fontColor);
}

#dashboard #dashboardInfo {
    display: flex;
    align-items: center;
    grid-area: header;
    justify-content: space-between;
}

#dashboard #dashboardInfo h2 {
    border: none;
    margin-right: 5px;
}

#dashboard #dashboardTitle {
    display: flex;
    align-items: center;
}

#dashboard #dashboardInfo #dashboardSearch {
    display: flex;
    align-items: center;
    background-color: var(--searchBack);
    padding: 5px 10px;
    border-radius: 8px;
}

#dashboard #dashboardInfo #dashboardSearch input {
    background-color: transparent;
    border: none;
    font-size: 1rem;
    font-family: var(--monospace);
    color: var(--fontColor);
}

#dashboard #dashboardInfo #dashboardSearch input:focus {
    outline: none;
}

#dashboard #dashboardInfo #dashboardSearch input::placeholder {
    color: var(--fontColor);
}
#dashboard #dashboardInfo #dashboardSearch input:-ms-input-placeholder {
    color: black;
}
#dashboard #dashboardInfo #dashboardSearch input::-ms-input-placeholder {
    color: black;
}

#dashboard #dashboardInfo #dashboardSearch img {
    height: 24px;
    width: 24px;
    cursor: pointer;
    filter: var(--svgFilter);
}

/* table */

#dashboard #dashboardBugs {
    grid-area: dBugs;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    box-shadow: var(--insetBoxShadow);
    overflow-y: auto;
    position: relative;
}

#dashboard #dashboardBugs::-webkit-scrollbar-track {
    background-color: transparent;
}

#dashboard #dashboardBugs::-webkit-scrollbar-thumb {
    background-color: transparent;
}

#dashboard #buttonPlacementDiv{
    grid-area: dBugs;
    height: 100%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 15px;
    position: relative;
}

#dashboard #submitBugPopup {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 0px 4px var(--borderBottomColor);
    background-color: var(--backgroundColor);
    border-radius: 8px;
    z-index: 9999;
}

#dashboard #buttonPlacementDiv button {
    position: sticky;
    width: 18ch;
    display: flex;
    flex-direction: row;
    padding: 5px 8px;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    border: none;
    background-color: var(--buttonColorDarkGreen);
    color: white;
    transition: 0.2s ease;
    font-weight: 600;
    align-self: flex-end;
    bottom: 0%;
    right: 0%;
    z-index: 999;
}

#dashboard #buttonPlacementDiv button:hover {
    background-color: var(--buttonColorDarkGreenHover);
}

#dashboard #buttonPlacementDiv button img {
    height: 20px;
    width: 20px;
    margin-right: 3px;
    filter: brightness(0) invert(1);
}

#dashboard #dashboardBugs table {
    font-size: 1rem;
    border-collapse: collapse;
    z-index: 99;
}

#dashboard #dashboardBugs table tr {
    border-bottom: 1px solid var(--borderColor);
    transition: 0.2s ease;
}

#dashboard #dashboardBugs table tr:first-of-type {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
}
#dashboard #dashboardBugs table td:nth-of-type(2) {
    min-width: 30vw;
}

#dashboard #dashboardBugs table tr:last-of-type {
    border-bottom: none;
}

#dashboard #dashboardBugs table tr:first-of-type:hover {
    background-color: transparent;
    cursor:alias;
}

#dashboard #dashboardBugs table tr:hover {
    background-color: var(--tableHover);
}

#dashboard #dashboardBugs table td {
    padding: 5px 8px;
    cursor: pointer;
}
#dashboard #dashboardBugs table td:first-of-type {
    white-space: nowrap;
}

#dashboard #dashboardBugs table td:first-of-type {
    width: 8ch;
}

#dashboard #dashboardBugs table td:last-of-type {
    width: 13ch;
}

#dashboard #dashboardBugs .status {
    padding: 3px;
    font-size: 0.6rem;
    font-weight: 600;
    display: flex;
    background-color: var(--dashBackground);
    border-radius: 8px;
    justify-content: center;
    text-transform: uppercase;
}

#dashboard #dashboardBugs .open .status {
    background-color: var(--openBug);
    color: var(--openFont);
}

#dashboard #dashboardBugs .closed .status {
    background-color: var(--closeBug);
    color: var(--closeColor);
}

#dashboard #dashboardBugs .ongoing .status {
    background-color: var(--ongoingBug);
    color: var(--ongoingColor);
}

#dashboard #dashboardBugs .inReview .status {
    background-color: var(--inReviewBug);
    color: var(--inReviewColor);
}

#dashboard .priority {
    font-size: 0.6rem;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    background-color: rgb(27, 27, 0);
    width: 5ch;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    padding: 4px 5px;
    margin: auto;
}

#dashboard .low .priority {
    background-color: var(--priorityLow);
}

#dashboard .med .priority {
    background-color: var(--priorityMed);
}

#dashboard .high .priority {
    background-color: var(--priorityHigh);
}

/* bug status */

#dashboard #side{
    grid-area: side;
    display: flex;
    flex-direction: column;
}

#dashboard #dashboardStats #bugFilters{
    display: flex;
    padding-top: 10px;
    flex-wrap: wrap;
}

#dashboard #bugFilters .filter{
    width: fit-content;
    padding: 5px 10px;
    padding-bottom: 7px;
    border-radius: 16px;
    cursor: pointer;
    font-size: .8rem;
    margin: 2px 2px;
    color: white;
    border: 1px solid;
}

#dashboard #bugFilters .filter strong{
    font-size: .8rem;
}

#dashboard #bugFilters .openFilter{
    background-color: var(--openBug);
    border-color: var(--openBug);
}

#dashboard #bugFilters .ongoingFilter{
    background-color: var(--ongoingBug);
    border-color: var(--ongoingBug);
}

#dashboard #bugFilters .closeFilter{
    background-color: var(--closeBug);
    border-color: var(--closeBug);
}

#dashboard #bugFilters .inReviewFilter{
    background-color: var(--inReviewBug);
    border-color: var(--inReviewBug);
}

#dashboard #bugFilters .lowFilter{
    background-color: var(--priorityLow);
    border-color: var(--priorityLow);
}

#dashboard #bugFilters .medFilter{
    background-color: var(--priorityMed);
    border-color: var(--priorityMed);
}

#dashboard #bugFilters .highFilter{
    background-color: var(--priorityHigh);
    border-color: var(--priorityHigh);
}

#dashboard #bugFilters .overdueFilter{
    background-color: var(--statusOverFont);
    border-color: var(--statusOverFont);
    text-transform: capitalize;
}

#dashboard #bugFilters .dayFilter{
    background-color: var(--statusDayFont);
    border-color: var(--statusDayFont);
    text-transform: capitalize;
}

#dashboard #bugFilters .weekFilter{
    background-color: var(--statusWeekFont);
    border-color: var(--statusWeekFont);
    text-transform: capitalize;
}

#dashboard #dashboardStats #bugFilters button{
    border: 1px solid var(--borderColor);
    color: var(--fontColor);
    background-color: transparent;
}

#dashboard #dashboardStats #bugFilters button:hover{
    background-color: var(--tableHover);
}

#dashboard #bugFilters .toggled{
    background-color: transparent !important;
    border-color: var(--borderColor) !important;
    color: var(--fontColor);
}

#dashboard #bugFilters #teamFilters{
    position: relative;
    
}

#dashboard #bugFilters .teamFilter{
    width: max-content;
    display: flex;
}

#dashboard #bugFilters #teamFiltersContainer{
    min-height: 5ch;
    min-width: 300px;
    max-width: 800px;
    background-color: var(--backgroundColor);
    box-shadow: 0px 0px 3px var(--notFont);
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
    z-index: 99999;
    border-radius: 12px;
    flex-wrap: wrap;
    padding: 5px;
    text-transform: capitalize;
}

#dashboard #bugFilters #teamFiltersContainer #emptyFiltersPrompt{
    display: flex;
    margin: 0;
    padding: 0;
    margin: auto;
}

#dashboard #dashboardStats #bugFilters .teamFiltersActive{
    display: flex;
}

#dashboard #dashboardStats #bugFilters .teamFiltersActive ~ button{
    background-color: rgb(5, 61, 122); 
    border-color: rgb(5, 61, 122);
    color: white;
}

/* feed */

#dashboard #dashboardFeed {
    grid-area: feed;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

#dashboard #dashboardFeed h2{
    padding: 3px 0;
}

#dashboard #dashboardFeed #feedDiv {
    box-shadow: var(--insetBoxShadow);
    border-radius: 12px;
    padding: 15px;
    overflow-y: auto;
    height: 100%;
}

#dashboard #dashboardFeed #feedDiv::-webkit-scrollbar {
    width: 0;
}

#dashboard #dashboardFeed #feedDiv::-webkit-scrollbar-track {
    background-color: transparent;
}

#dashboard #dashboardFeed #feedDiv::-webkit-scrollbar-thumb {
    background-color: transparent;
}

#dashboard #dashboardFeed #feedDiv .feedItem {
    margin: 5px 0;
    align-items: center;
}

#dashboard #dashboardFeed #feedDiv .feedItem .updateTime {
    font-size: 0.6rem;
    opacity: 0.5;
    font-weight: 600;
}

#dashboard .feedItem{
    display: flex;
    padding: 8px 0;
    border-bottom: 1px solid var(--borderColor);
}

@media  screen and (max-width: 700px){
    #dashboard {
        display: flex;
        flex-direction: column;
        padding: 15px;
        padding-bottom: 0;
        max-width: 100vw;
    }

    #dashboard #dashboardInfo {
        font-size: .75rem;
    }

    #dashboard #dashboardInfo #dashboardSearch {
        padding: 4px 8px;
    }
    
    #dashboard #dashboardInfo #dashboardSearch input {
        font-size: .8rem;
    }

    #dashboard #dashboardInfo #dashboardSearch img {
        height: 12px;
        width: 12px;
    }

    #dashboard #dashboardBugs {
        height: 100%;
    }

    #dashboard #dashboardBugs table td:first-of-type {
        font-size: 1rem;
    }

    #dashboard #dashboardBugs table td:nth-of-type(4) {
        display: none;
    }
    

    #dashboard #buttonPlacementDiv{
        position: absolute;
        width: auto;
        height: auto;
        bottom: 15px;
        right: 10px;
    }

    #dashboard #side{
        display: none;
    }
    
}