#getStarted{
    color: var(--fontColor);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

#upper{
    padding: 0px 25px;
    padding-top: 15px;
}

#getStarted .newTeamDiv{
    display: flex;
    height: 100%;
}