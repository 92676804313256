#inReview {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
    color: var(--fontColor);
    max-height: calc(100vh - 58px);
    overflow-y: auto;
}

#inReview table {
    margin-top: 15px;
}

#inReview table td {
    min-width: 5ch;
}

#inReview table td:nth-of-type(2) {
    min-width: 50vw;
}

#inReview table td:last-of-type {
    width: 12ch;
}

#inReview table .bugsLabel {
    display: flex;
}

#inReview table tr:first-of-type {
    font-size: .9rem;
}

#inReview table td:last-of-type {
    width: 18ch;
}

#inReview input {
    background-color: var(--notFont);
    color: var(--fontColor);
    border: 6px;
    border-radius: 3px;
}

#inReview .reviewButtons {
    display: flex;
}

#inReview button {
    cursor: pointer;
}

#inReview button {
    padding: 5px;
    margin: 5px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    color: var(--fontColor);
    background-color: var(--notFont);
    border: var(--borderBottomColor);
}

#inReview .accept {
    background-color: var(--green);
}

#inReview .accept:hover {
    background-color: var(--greenDarker);
}

#inReview .decline {
    background-color: var(--red);
}

#inReview .decline:hover {
    background-color: var(--redDarker);
}