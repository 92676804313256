#submitBug {
    background-color: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    padding: 10px;
}

#newBug {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 12px;
}

#newBug #submitClose {
    cursor: pointer;
}

#submitBug form {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    align-items: center;
}

#submitBug form > * {
    margin: 3px 0;
    width: 100%;
}

#submitBug form > span > span {
    margin: 5px 5px;
}
#submitBug form span {
    display: flex;
    justify-content: space-between;
}

#submitBug select {
    font-size: 1.2rem;
    border: none;
    text-align: start;
}

#submitBug input[type="text"],
#submitBug textarea {
    padding: 5px;
    font-size: 1.2rem;
    border: none;
    resize: none;
    background-color: var(--inputBackground);
    color: var(--fontColor);
    border-radius: 3px;
}

#submitBug input[type="submit"] {
    color: white;
    border: none;
    background-color: var(--buttonColorDarkGreen);
    padding: 10px;
    border-radius: 16px;
    width: 20ch;
    transition: .2s ease;
    cursor: pointer;
}


#submitBug input[type="submit"]:hover {
    background-color: var(--buttonColorDarkGreenHover);
}

