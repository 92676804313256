#team{
    background-color: var(--backgroundColor);
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: var(--fontColor);
}

#team #teamHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--notFont);
    padding-bottom: 10px;
    margin-bottom: 15px;
}

#team #teamTabs{
    display: flex;
    flex-grow: 1;
}

#team .teamTabDiv{
    display: flex;
    justify-content: space-between;
}

#team #teamTabs .teamTabDiv .teamTabName{
    border: none;
    margin: auto 0;
}

#team #teamTabs > div{
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: var(--insetBoxShadow);
    margin: 0 5px;
    border-radius: 12px;
}




#team table {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
}

#team table button{
    margin-top: 10px;
}

#team table tr {
    display: flex;
    padding: 10px 0;
    align-items: center;
    position: relative;
    width: 100%;
}

#team table th{
    min-width:10ch;
}

#team table th:last-of-type{
    align-self: flex-end;
}

#team table th:last-of-type{
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    text-align: end;
    right: 15px;
}

#team table th img{
    height: 15px;
    width: 15px;
    position: relative;
}

#team table th:nth-of-type(2){
    min-width: 15ch;
}

#team table th:nth-of-type(3){
    min-width: 5ch;
    text-transform: capitalize;
}

#team #teamProjects table th:first-of-type{
    min-width: 20ch;
}

#team form{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

#team form input{
    font-size: 1rem;
    padding: 5px;
    margin-right: 3px;
    background-color: var(--notFont);
    color: var(--fontColor);
    border: none;
    border-radius: 3px
}

#team > div button{
    background-color: var(--green);
    border: none;
    padding: 10px;
    color: white;
    font-size: 1rem;
    border-radius: 6px;
    cursor: pointer;
}

#team #inviteButton{
    margin-bottom: 12px;
}

#team .invite{
    display: flex;
    align-items: center;
}

#team .buttons button{
    padding: 5px;
}

#team > div button:hover{
    background-color: var(--greenDarker);
}

#team > div #inviteButton button:hover, #team #newProjectButton:hover{
    background-color: var(--greenDarker);
}

#team .buttons button:last-of-type {
    background-color: var(--red);
}

#team > div button:last-of-type:hover{
    background-color: var(--redDarker);
}

#team table tr{
    cursor: default;
}

#team table tr th{
    max-width: 10ch;
    padding: 0 3px;
}

#team table .invite th{
    max-width: 110ch;
    padding: 0 3px;
}


#team #teamTabs table .user th:last-of-type{
    width: 5px;
}

#team #teamUsers table{
    position: relative;
}


#team #teamUsers table tr{
    display: flex;
}



#team #teamUsers table th:first-of-type{
    min-width: 30px;
    padding: 0 5px;
}



#team table th:last-of-type{
    justify-self: flex-end;
}

#team table th img{
    filter: var(--svgFilter);
}

#team table .invite {
    border-top: 1px solid var(--borderColor);
}

#team .invite:first-of-type {
    margin-top: 51px;
}

#team .buttons{
    display: flex;
}

#team .buttons button{
    padding: 3px 10px;
    margin-left: 5px;
}

#morePrompt{
    position: absolute;
    z-index: 99969999;
    min-width: 15ch;
    left: -100px;
    top: 15px;
    background-color: var(--backgroundColor);
    padding: 5px 15px;
    box-shadow: var(--insetBoxShadow);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#morePrompt > *{
    margin: 2px;
}

#morePrompt p{
    font-size: .75rem;
    font-weight: 400;
    opacity: .6;
}

#morePrompt p:hover{
    opacity: 1;
}

.moreHidden{
    display: none;
}