#contact{
    padding: 0 8%;
}

#contact, #contact a{
    color: white;
    text-decoration: none;
}

#contact button {
    color: white;
    border: 1px solid white;
    padding: 10px 15px;
    font-weight: 700;
    border-radius: 3px;
    cursor: pointer;    
}

#contact section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact section span{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 15px;
}

#contact section p{
    width: 60ch;
    margin: auto;
}

#contact form{
    display: flex;
    flex-direction: column;
    width: 50vw;
    margin-top: 30px;
}

#contact form input{
    margin-bottom: 5px;
    background-color: transparent;
    color: white;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.559);
    padding: 5px;
}

#contact form textarea{
    background-color: transparent;
    color: white;
    padding: 5px;
    font-size: 1rem;
    margin-top: 15px;
}

#contact form button{
    background-color: var(--green);
    width: 15ch;
    margin-left: auto;
    margin-top: 15px;
}

#contact form button:hover{
    background-color: var(--greenDarker);
}