* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: 1.2;
}

body #home {
    padding: 0 8%;
}

#home *{
    color: white;
}

#home button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;

    padding: 10px 15px;
    border-radius: 3px;
    cursor: pointer;
}

#home a {
    text-decoration: none;
}

/* Nav */

#home nav{
    display: flex;
    margin: 0;
}

#hamburgerMenu{
    display: none;
}

/* hero sect */

#home #hero {
    display: flex;
    height: calc(100vh - 50px);
    position: relative;
    align-items: center;
}

#home #hero > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}

#home #hero div:first-of-type p {
    margin: 5px 0;
    opacity: 0.7;
    font-size: 1.4rem;
    width: 80%;
}

#home #hero h2 {
    font-size: 3rem;
}

#home #hero #heroCTA {
    width: 55vw;
    background-color: rgba(21, 21, 21, 0.747);
    padding: 0 20px;
    height: 50%;
    border-radius: 6px;
}

#home #hero #heroImage {
    position: absolute;
    z-index: -10;
    right: 0;
    display: flex;
    height: 100%;
    align-items: center;
}

#home #hero img {
    width: 55vw;
    border-radius: 6px;
}

#home #hero button {
    color: white;
    border: none;
    padding: 11px 25px;
    margin-top: 15px;
    background: rgb(111, 197, 128);
    background: var(--BGGradient);
    font-size: 1.2rem;
    font-weight: 700;
}

#home #hero #chevron{
    position: absolute;
    height: 30px;
    width: 30px;
    left: 47.5%;
    transform: rotate(180deg);
    top: 90%;
    animation-name: scroll;
    animation-duration: .8s;
    animation-iteration-count: 2;
    filter: brightness(0) invert(1);
}

@keyframes scroll {
    0% {transform: rotate(180deg) translateY(0px);}
    50% {transform: rotate(180deg) translateY(-20px);}
    100% {transform: rotate(180deg) translateY(0px);}
}

/* feature section */

#home #features h3{
    font-size: 2rem;
    color: white;
}

#home #features img{
    filter: invert(1);
}

#home #features h3, #home #features h2{
    margin-bottom: 12px;
}

#home #features p{
    font-size: 1.3rem;
}

#home #features .featureHero{
    margin: 30px 30%;
}

#home #features .featureHero h2{
    font-size: 2.2rem;
}

#home #features .featuresContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

#home #features .featuresContainer > div{
    display: flex;
    align-items: center;
    padding: 25px 20px;
    background: rgba( 43, 43, 43, 0.25 );
    box-shadow: 0 4px 32px 0 rgba( 2,53,60, 0.25 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

#home #features .featuresContainer > div > div{
    height: 100%;
}

#home #features .featuresContainer img{
    height: 100px;
    width: 100px;
    margin-right: 15px;
    padding: 3px;
}

/* footerCta sect */

#home #footerCTA{
    display: flex;
    margin: 50px 0;
    padding: 50px 25px;
    align-items: center;
    justify-content: space-between;
}

#home #footerCTA h2{
    font-size: 3.5rem;
    width: 8ch;
}

#home #footerCTA button{
    color: white;
    border: none;
    padding: 11px 25px;
    margin-top: 15px;
    background: rgb(111, 197, 128);
    background: var(--BGGradient);
    font-size: 1.5rem;
    font-weight: 700;
}

#hamburgerButton{
    display: none;
}

@media only screen and (max-width: 768px) {
    body #home{
        padding: 5px 10px;
        overflow-x: hidden;
    }
    nav{
        font-size: .9rem;
    }
    nav #anchors, #navButtons{
        display: none;
    }

    #hamburgerMenu{
        display: block;
    }

    #hamburgerButtons{
        display: block;
    }

    #home #hero #heroCTA {
        width: auto;
    }
    #home #hero div:first-of-type p {
        margin: 5px 0;
        opacity: 0.7;
        font-size: 1rem;
        width: 80%;
    }
    #home #hero h2 {
        font-size: 2rem;
    }
    #home #hero #heroImage {
        position: absolute;
        z-index: -10;
        left: 70%;
        display: flex;
        align-items: center;
    }
    #home #features .featureHero{
        margin: 0;
        padding: 0 20px;
        padding-bottom: 25px;
    }
    #home #features .featuresContainer{
        display: flex;
        flex-direction: column;
    }
    #home #features .featuresContainer > div{
        flex-direction: column;
    }
    #home #footerCTA{
        flex-direction: column;
        align-items: flex-start;
    }
    #home #footerCTA h2{
        font-size: 2.5rem;
    }
}