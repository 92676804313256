#bugs{
    color: var(--fontColor);
    padding: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    height: calc(100vh - 60px);
}

#bugs #bugDivContainer{
    display: grid;
    justify-content: space-around;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    height: 100%;
}

#bugs #bugsUpper{
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 15px;
}


#bugs .thumbnail{
    width: 100%;
}


#bugs #bugsUpper #bugsButton{
    position: relative;
}

#bugs #commitChanges {
    display: flex;
    align-items: center;
}
#bugs #bugsUpper .commitHidden {
    display: none;
    opacity: 0;
}

#bugs #bugsUpper #commitChanges button{
    margin-left: 5px;
    padding: 5px 10px;
    font-weight: 600;
    color: white;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}

#bugs #bugsUpper #commitChanges button:first-of-type{
    background-color: var(--green);
}

#bugs #bugsUpper #commitChanges button:first-of-type:hover{
    background-color: var(--greenDarker);
}

#bugs #bugsUpper #commitChanges button:last-of-type{
    background-color: var(--red);
}

#bugs #bugsUpper #commitChanges button:last-of-type:hover{
    background-color: var(--redDarker);
}

#bugs #bugsUpper #bugsButton #submitBugPopup{
    left: 0;
}

#bugs #bugDivContainer > div{
    box-shadow: var(--insetBoxShadow);
    border: var(--insetBoxShadow);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    max-width: 31vw;
    transition: .2s ease;
    overflow-y: scroll;
    height: calc(100vh - 130px);
}

#bugs #bugDivContainer .inReviewHidden{
    height: 0;
    overflow: hidden;
}

#bugs #bugDivContainer > div .inReview{
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

#bugs #bugDivContainer .bugsDiv{
    margin-top: 5px;
}

#bugs #bugDivContainer .bugsDivUpper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#bugs #bugDivContainer .bugsDiv .bug{
    box-shadow: var(--insetBoxShadow);
    padding: 10px;
    margin: 5px 0;
    border-radius: 6px;
    cursor: pointer;
}

#bugs #bugDivContainer .bugsDiv .bug:hover{
    background-color: var(--backgroundHover);
}

#bugs #bugDivContainer .bugsDiv .bugDescription{
    margin: 10px 0;
}

#bugs #bugDivContainer .bugsDiv .bugDescription p{
    word-wrap: break-word;
    max-width: 100%;
    
}

#bugs #bugDivContainer .bugsDiv .bugUpperInfo{
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

#bugs #bugDivContainer .bugsDiv .bugUpperInfo span:first-of-type{
    font-weight: 600;
}

#bugs #bugDivContainer .bugsDiv .labels {
    display: flex;
    margin: 5px 0;
}

#bugs #bugDivContainer .bugsDiv .bugBottomInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

#bugs #bugDivContainer .inReview .bugsDivUpper .inReviewInfo{
    display: flex;
    align-items: center;
}

#bugs #bugDivContainer .inReview .bugsDivUpper .inReviewInfo p{
    margin-right: 5px;
}

#bugs #bugDivContainer .inReview button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    transform: rotate(180deg);
}

#bugs #bugDivContainer .inReview .bugsDivUpper .inReviewButtonActive{
    transform: rotate(0deg);
}

#bugs #bugDivContainer .inReview .bugsDivUpper button img{
    filter: var(--svgFilter);
    height: 10px;
    width: 12px;
}

#bugs ::-webkit-scrollbar {
    width: 0px;
}

#bugs ::-webkit-scrollbar-track {
    background-color: var(--notFont);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

#bugs ::-webkit-scrollbar-thumb {
    background-color: var(--fontColor);
}

#bugsButton {
    position: relative;
}

#bugs #bugsButton button {
    display: flex;
    padding: 5px 8px;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    border: none;
    background-color: var(--buttonColorDarkGreen);
    color: white;
    transition: 0.2s ease;
    font-weight: 600;
    text-transform: uppercase;
}

#bugs button:hover {
    background-color: var(--buttonColorDarkGreenHover);
}

#bugs button img {
    height: 20px;
    width: 20px;
    margin-right: 3px;
    filter: brightness(0) invert(1);
}

#bugs #submitBugPopup {
    position: absolute;
    top: 105%;
    left: 300px;
    padding: 10px;
    background-color: var(--backgroundColor);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.719);
    border-radius: 8px;
    z-index: 9999;
}

#bugs input{
    background-color: var(--notFont);
    color: var(--fontColor);
    border: 6px;
    border-radius: 3px;
    font-size: 1.2rem;
    padding: 2px 4px;
}

#bugs input:focus{
    outline: none;
}