#invite{
    padding: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#invite form {
    margin: 10px 0;
    display: flex;
    border-radius: 3px;
    overflow: hidden;
}

#invite form input[type="text"]{
    background-color: var(--notFont);
    color: var(--fontColor);
    padding: 5px 8px;
    font-size: 1rem;
    border: none;
    width: 30ch;
}

#invite form input[type="button"]{
    background-color: var(--green);
    color: var(--fontColor);
    font-weight: 700;
    border: none;
    padding: 5px;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
}


#invite form input[type="button"]:hover{
    background-color: var(--greenDarker);
    color: var(--fontColor);
    font-weight: 700;
}

#invite #searchUsers{
    display: flex;
    flex-direction: column;
}

#invite #searchResults{
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: 10px;
    color: var(--fontColor);
    box-shadow: var(--insetBoxShadow);
    height: 100%;
}

#invite .user{
    display: flex;
    min-height: 30px;
    padding: 10px 0;
}

#invite .user .info {
    display: flex;
    align-items: center;
}

#invite .user .avatar {
    overflow: hidden;
}

#invite .user .avatar img {
    width: 24px
}

#invite .user button {
    background-color: var(--green);
    color: var(--fontColor);
    font-weight: 700;
    border: none;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    width: 7ch;
}


#invite .user button:hover {
    background-color: var(--greenDarker);
    color: var(--fontColor);
    font-weight: 700;
}

#invite table tr{
    display: flex;
    align-items: center;
    cursor: default;
}

#invite table td:first-of-type{
    width: 24px;
}


#invite table td:first-of-type {
    text-transform: none;
}

#invite table tr:last-of-type {
    border-bottom: none;
}

#invite table tr:first-of-type:hover {
    background-color: var(--tableHover);
}

#invite table tr:hover {
    background-color: var(--tableHover);
}